.wrapper-class {
    border-radius: 5px; 
    height: 800px;
    align-self: stretch;
    /* max-width: 1000px; */
}

.toolbar-class {
    display: flex;
}

.editor-class {
    background-color: aliceblue;
}
h2 {
    color: black;
}
h3 {
    color: black;
}