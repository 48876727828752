.account__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: top;
    margin-top: 20px;
    align-items: flex-start;
}
.account__wrapper__inner{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 20px;
    flex-grow: 4;
    
}
.dropdown__serviceType{
    max-width: 500px;
}
.org__box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 20px;
    max-width: fit-content;
    /* box-shadow: #ccc 0px 0px 10px; */
    padding: 10px;
    border-radius: 5px;
}
.org__logo{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 10px;

}
.account__wrapper__inner--personal{
    flex: 4;
  max-width: 1000px;
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 20px;
}
.org__wrapper{
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 20px;
}

.register__btn{
    color: #ccc;
    background-color: rgba(133, 156, 27, 0.719);
    border: none;
    width: 150px;
    height: 60px;
    font-size: 1.2rem;
    margin-top: 15px;
    border-radius: 4px;
    cursor: pointer;

}
.register__btn:hover{
    transition: all 0.2s ease-in-out;
    background-color: rgba(75, 129, 64, 0.719);
    scale : 1.1;
}
.create__btn:hover{
    transition: all 0.2s ease-in-out;
    background-color: rgba(133, 156, 27, 0.719);
    scale : 1.1;
}
.create__btn{
    color: #ccc;
    background-color: rgba(75, 129, 64, 0.719);
    border: none;
    width: 150px;
    height: 60px;
    font-size: 1.2rem;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;
}

h1{
    font-size: 2.5rem;
    margin-bottom: 25px;
    text-decoration: none;
    background-color: none;
    color: #ccc;
    padding :10px 10px;
}
h2{
    font-size: 1.5em;
    margin-left: 15px;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: none;
    color: #ccc;
}
.userData{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
    font-size: 1.3rem;
    margin-bottom: 25px;
    padding: 0 20px;
    text-decoration: none;
    /* background-color: #f2f2f2; */
    color: #999;
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: #ccc 0px 0px 5px;
    border-radius: 5px;
}
ul{
    font-size : 1.2em;
    color: #ccc;
}
p{
    font-size: 0.8rem;
    text-decoration: none;
    align-self: flex-start;
    color: #ccc;
}
.list__element{
    font-size: 1.2rem;
    padding-left: 15px;
    text-decoration: none;
    align-self: flex-start;
    color: #ccc;
}
.request__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    box-shadow: #888 0px 0px 10px;

}
.actions{
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    margin-top: 20px;
}
button.delete__btn{

    color: #ccc;
    background-color: rgba(128, 128, 128, 0.733);
    border: none;
    width: 150px;
    height: 40px;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 4px;
    cursor: pointer;
}
button.delete__btn:hover{
    transition: all 0.2s ease-in-out;
    background-color: rgba(201, 33, 33, 0.933);
    scale : 1.1;
}
button.edit__btn{

    color: #ccc;
    background-color: rgba(75, 129, 64, 0.719);
    border: none;
    width: 160px;
    height: 50px;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
button.edit__btn.pencil{
    color: #ccc;
    background-color: #ccc;
}
button.edit__btn:hover{
    transition: all 0.2s ease-in-out;
    background-color: rgb(75, 129, 64);
    scale : 1.1;
}
.name__input{
    font-size: 1.3rem;
    color: #1d1a1a;
    margin-left: 20px;
    border-radius: 4px;
}