
.view__btn{
    transition: all 0.3s ease-in-out;
    background-color: #2e7430d0;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 5px; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

    cursor: pointer;

}
.view__btn:hover{
    transition: all 0.3s ease-in-out;
    background-color: #2e7430;
}
.delete__table__btn{
    transition: all 0.3s ease-in-out;
    background-color: darkred;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 0px; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

    cursor: pointer;
}
.delete__table__btn:hover{
    background-color: red;
}