input[type=file]::file-selector-button {
    border: 2px solid #666;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #ccc;
    transition: 0.3s;
  }
  
  input[type=file]::file-selector-button:hover {
    background-color: #81ecec;
    border: 2px solid #00cec9;
    scale: 1.1;
  }
  .error__card{
    color: rgba(201, 33, 33, 0.933);
    border: none;
    width: 100%;
    font-size: 0.9rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .success__card{
    color: rgb(75, 129, 64);
    border: none;
    width: 100%;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn__upload{
    color: #ccc;
    background-color: rgba(128, 128, 128, 0.733);
    border: none;
    width: 150px;
    height: 40px;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  .btn__upload:hover{
    transition: all 0.2s ease-in-out;
    background-color: green;
    scale : 1.1;
  }
.file__upload{
    /* border: 1px solid #ccc; */
    box-shadow: #eee 0px 0px 5px;
    border-radius: 5px;
    display: inline-block;
    padding: 0px 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

.upload__form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 150px;
}