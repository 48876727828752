.request__wrapper__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* margin-top: 20px; */
}
h3{
    font-size: 1rem;
    color: #ccc;

}
.deny__btn{
    color: #ccc;
    background-color: rgb(146, 38, 38);
    border: none;
    width: 140px;
    height: 40px;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 5px;
    box-shadow: #66666652 0px 0px 5px;
}
.accept__btn{
    color: #ccc;
    background-color: rgba(75, 129, 64, 0.719);
    border: none;
    width: 150px;
    height: 40px;
    font-size: 1.2rem;
    margin:10px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: #66666652 0px 0px 5px;
}
.accept__btn:hover{
    transition: all 0.2s ease-in-out;
    background-color: rgba(133, 156, 27, 0.719);
    scale : 1.1;
}
.deny__btn:hover{
    transition: all 0.2s ease-in-out;
    background-color: rgba(139, 15, 6, 0.719);
    scale : 1.1;
}
.role__btn{
    color: #ccc;
    background-color: rgba(75, 129, 64, 0.719);
    border: none;
    width: 100px;
    height: 40px;
    font-size: 1.2rem;
    margin:10px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: #66666652 0px 0px 5px;
}
.role__btn:hover{
    transition: all 0.2s ease-in-out;
    scale : 1.1;
    background-color: rgba(133, 156, 27, 0.719);
}
.--basic{
    background-color: #666;
}
.--basic:hover{
    background-color: #888;
}
.--admin{
    background-color: rgb(68, 38, 38);
}
.--admin:hover{
    background-color: rgb(146, 38, 38);
}