.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.card__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 100ms;
  height: 100%;
}

.results > div {
  overflow: hidden;
}
code.error {
  display: block;
  padding: 1em;
  margin: 1em;
  background-color: #ffffff;
  color: #f00000;
  line-height: 1.5;
}
