.MuiAutocomplete-popupIndicator{
  max-width: 40px;
}
.MuiAutocomplete-clearIndicator{
  max-width: 40px;
}

form.loginForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
form.loginForm input{
  margin: 10px;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
form.loginForm button{
  margin: 10px;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #007bff;
  color: white;
}
form button:hover{
  background-color: #0056b3;
}
form h1,label{
  margin-bottom: 20px;
  color : #ccc;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.testButton {
  margin-top: 20px;
}

small {
  display: block;
  font-size: 12px;
  color: #ccc;
}

h1 {
  font-size: 2em;
  border-bottom: 1px solid rgb(248, 248, 248);
  margin-bottom: 25px;
}
p {
  font-size: 0.8rem;
  text-decoration: underline;
  align-self: flex-start;
}
p.help{
  color: lightcoral;
}
p.switch__register{
  color: #ccc;
  text-decoration: none;
}
a{
  color: #b4d5f8;
}


